body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#footer {
  margin-top: 7rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#getStarted {
  background: linear-gradient(to right, #4b86be, #98edd6);
  /* height: 100vh */
}

.headerLogo {
  background: #fff;
  padding: 10px;
  max-width: 200px;
  border-radius: 0px 0px 10px 10px;
}

.headerBar {
  position: absolute;
  justify-content: space-between;
}

.LogInBtn {
  text-align: end;
}


.Card {
  position: relative;
  /* width:12.5%;
margin:5px;
color: #3e3e3e;
float: left; */
}

.card-image {
  border: 1px solid #f5f5f5;
  border-radius: 100%;
  height: 90px;
  right: 0;
  margin: auto;
  left: 0;
  position: absolute;
  background: white;
  top: -50px;
  width: 90px;

}

ul.serviceButtons li {
  border-radius: 5px;
  list-style: none;
  padding: 10px 20px;
  background: #fff;
  display: inline-block;
  margin: 5px;
}

ul.serviceButtons li a img {
  max-height: 2.5em;
}

.footer {
  /* bottom: 0; */
  margin-top: 7rem;
}

.card-image img {
  padding: 18px;
  width: 100%;
}

.serviceHeading h2 {
  position: relative;
}

.serviceHeading h2:after {
  content: "";
  display: block;
  width: 110px;
  height: 4px;
  background: #92e6d6;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -10px;
  position: absolute;
}

@media only screen and (max-width: 728px) {
  .LogInBtn {
    text-align: center;
    margin-top: 20px !important;
  }

  .footer {
    position: relative;
  }

  .col {
    flex: none !important;
    width: 100% !important;
  }

  .headerLogo {
    margin: auto;
  }

  .right-img {
    display: none;
  }

  .Card {
    margin-bottom: 25px;
  }

  ul.serviceButtons {
    padding: 0;
  }

  ul.serviceButtons li {
    margin: 2px;
  }

  .service-img {
    width: 200px !important;
  }

}

.center {
  margin: 20px auto;
  padding: 20px 20px;
}

/* .slick-prev:before {
  content: "«";
  color: rgb(36, 29, 29);
  font-size: 30px;
}

.slick-next:before {
  content: "»";
  color: rgb(15, 13, 13);
  font-size: 30px;
}

.single-service {
  background: #F1F1F1;
  border-radius: 32.5px;
  padding: 50px 20px;
  height: 227px;
  color: #263E56;
  transition: linear 0.2s;
}

.slider-image {
  background: #fff;
  box-shadow: 0px 0px 10px #ccc;
  margin: auto;
  /* height: 160px; */
/* text-align: center;
  padding: 7% 12% 7% 12%;
  border-radius: 20px;
} */

.slick-list {
  display: flex !important;
  justify-content: center;
}

.services-container {
  background-image: linear-gradient(to right, #97ecd6, #4b86be);
}

.servicecard {
  cursor: pointer;
  overflow: hidden;
}

.servicecard:hover {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}

.service-img {
  aspect-ratio: 26/10;
  background-size: contain;
  width: 100%;
}

.frame {
  width: 90%;
  margin: 40px auto;
  text-align: center;
}

.custom-btn {
  background: #e0e5ec;
  margin: 20px;
  width: 240px;
  height: 80px;
  color: #fff;
  border-radius: 10px !important;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, .1);
  outline: none;
}

.serviceImg {
  background-size: contain;
  max-width: 180px;
  max-height: 55px;
}

/* 16 */
.btn-service {
  border: none;
  color: #000;
}

.btn-service:after {
  border-radius: 10px !important;
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  box-shadow:
    -7px -7px 20px 0px #fff6,
    -4px -4px 5px 0px #fff6,
    7px 7px 20px 0px #0002,
    4px 4px 5px 0px #0001;
  transition: all 0.3s ease;
}

.btn-service:hover {
  color: #000;
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}

.btn-service:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}

.btn-service:active {
  top: 2px;
}

.loginBtn {
  border-radius: 5px !important;
  align-self: center;
  background: white;
  border: white;
  padding: 7px 21px;
  width: 120px;
  height: auto;
  color: #4b86be !important;
  font-weight: bold;
}

a {
  text-decoration: none !important;
  color: #4b86be !important;
}
